import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css';

// Swiper
import Swiper, {Navigation, Pagination, Autoplay, EffectFade} from 'swiper';
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

// GSAP
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

//Lenis
import Lenis from '@studio-freight/lenis'

const lenis = new Lenis()

lenis.on('scroll', (e) => {
  //console.log(e)
})

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)


$("document").ready(function() {
  
  if (document.querySelectorAll('.section-one').length > 0) {
/*
    const sectionOne = document.querySelector('.section-one');
    const sectionOnetitle = document.querySelector('.section-one .title');
   // const sectionOneBg = document.querySelector('.section-one .videoBg');
    
    const sectionOnevideo = document.querySelector('.section-one video');
    
    sectionOne.addEventListener('click', function() {
      // Check if the video is paused
      if (sectionOnevideo.paused) {
        // If paused, play the video and hide the title
        sectionOnevideo.play();
        
        //sectionOneBg.style.display = 'none';
        //sectionOnetitle.style.display = 'none';
      } else {
        // If playing, pause the video and show the title
        sectionOnevideo.pause();
       // sectionOneBg.style.display = 'block';
        //sectionOnetitle.style.display = 'block';
      }
    });*/
      
  }
  if (document.querySelectorAll('.journey').length > 0) {

    const journey = document.querySelector('.journey');    
    const journeyVideo = document.querySelector('.journey video');
    const journeyVideoBox = document.querySelector('.journey .videoBox');
    
    journey.addEventListener('click', function() {
      // Check if the video is paused
      if (journeyVideo.paused) {
        // If paused, play the video and hide the title
        journeyVideo.muted = false;
        journeyVideoBox.classList.add('active');
        journeyVideo.play();
        
      } else {
        // If playing, pause the video and show the title
        journeyVideo.pause();
        journeyVideoBox.classList.remove('active');
        journeyVideo.muted = false;
      }
    });
      
  }
  if (document.querySelectorAll('.gps').length > 0) {

    const gps = document.querySelector('.gps');    
    const gpsVideo = document.querySelector('.gps video');
    const gpsVideoBox = document.querySelector('.gps .videoBox');
    
    gps.addEventListener('click', function() {
      // Check if the video is paused
      if (gpsVideo.paused) {
        // If paused, play the video and hide the title
        gpsVideo.muted = false;
        gpsVideoBox.classList.add('active');
        gpsVideo.play();
        
      } else {
        // If playing, pause the video and show the title
        gpsVideo.pause();
        gpsVideoBox.classList.remove('active');
        gpsVideo.muted = false;
      }
    });
      
  }
  if (document.querySelectorAll('.artOfVictory').length > 0) {

    const artOfVictory = document.querySelector('.artOfVictory');    
    const artOfVictoryVideo = document.querySelector('.artOfVictory video');
    const artOfVictoryVideoBox = document.querySelector('.artOfVictory .videoBox');
    
    artOfVictory.addEventListener('click', function() {
      // Check if the video is paused
      if (artOfVictoryVideo.paused) {
        // If paused, play the video and hide the title
        artOfVictoryVideo.play();
        artOfVictoryVideoBox.classList.add('active');
        artOfVictoryVideo.muted = false;
        
      } else {
        // If playing, pause the video and show the title
        artOfVictoryVideo.pause();
        artOfVictoryVideoBox.classList.remove('active');
        artOfVictoryVideo.muted = false;
      }
    });
      
  }
  if (document.querySelectorAll('.activtions').length > 0) {

    const activtions = document.querySelector('.activtions');    
    const activtionsVideo = document.querySelector('.activtions video');
    
    
    activtions.addEventListener('click', function() {
      // Check if the video is paused
      if (activtionsVideo.paused) {
        // If paused, play the video and hide the title
        activtionsVideo.play();
        activtionsVideo.muted = false;
        activtions.classList.add('active');
      } else {
        // If playing, pause the video and show the title
        activtionsVideo.pause();
        activtionsVideo.muted = false;
        activtions.classList.remove('active');
        
      }
    });
      
  }
  
  if(window.innerWidth>700) {
    const slideCount = 11;
    const slideWidth = 280;
    const slideMargin = 40;
    const containerWidth = document.querySelector('.journeyAnimation').offsetWidth;

    // Total width of all slides combined
    const totalWidth = (slideCount * (slideWidth + slideMargin));

    // The amount to move the slides so the last one stops 50px from the right
    const maxScrollX = totalWidth - containerWidth + 50; 


    gsap.to('.journeyAnimation .slideFix', {
      x: -maxScrollX, // Move the slides left by the calculated maxScrollX
      ease: 'none',   // No easing, so the animation syncs directly with scroll
      scrollTrigger: {
        trigger: '.firstAnimation',       // Element that triggers the scroll animation
        start: 'top 50px',                  // Pin starts when .journeyAnimation reaches 50px from the top
        end: () => `+=${maxScrollX}`,       // The animation ends after scrolling the calculated distance
        scrub: true,                        // The animation will scrub (sync) with the scroll position
        pin: true,                          // Pin the element during the scroll animation
        pinSpacing: true,                   // Adds space after pinning to allow continued scrolling
        anticipatePin: 1,                   // Helps with pin performance
        markers: false                      // Set to true if you want to visualize the trigger points
      }
    });

   
    
    const slideCountTwo = 15;
    const slideWidthTwo = 300;
    const slideMarginTwo = 60;
    const containerWidthTwo = document.querySelector('.soundCarouselFix').offsetWidth;

    // Total width of all slides combined
    const totalWidthTwo = (slideCountTwo * (slideWidthTwo + slideMarginTwo));

    // The amount to move the slides so the last one stops 50px from the right
    const maxScrollXTwo = totalWidthTwo - containerWidthTwo + 50; 


    gsap.to('.soundCarouselFix .slideFix', {
      x: -maxScrollXTwo, // Move the slides left by the calculated maxScrollX
      ease: 'none',   // No easing, so the animation syncs directly with scroll
      scrollTrigger: {
        trigger: '.soundCarouselFix',       // Element that triggers the scroll animation
        start: 'top 50px',                  // Pin starts when .journeyAnimation reaches 50px from the top
        end: () => `+=${maxScrollX}`,       // The animation ends after scrolling the calculated distance
        scrub: true,                        // The animation will scrub (sync) with the scroll position
        pin: true,                          // Pin the element during the scroll animation
        pinSpacing: true,                   // Adds space after pinning to allow continued scrolling
        anticipatePin: 1,                   // Helps with pin performance
        markers: false                      // Set to true if you want to visualize the trigger points
      }
    });




    const slideCountThree = 10;
    const slideWidthThree = 300;
    const slideMarginThree = 60;
    const containerWidthThree = document.querySelector('.parisCarouselFix').offsetWidth;

    // Total width of all slides combined
    const totalWidthThree = (slideCountThree * (slideWidthThree + slideMarginThree));

    // The amount to move the slides so the last one stops 50px from the right
    const maxScrollXThree = totalWidthThree - containerWidthThree + 50; 


    gsap.to('.parisCarouselFix .slideFix', {
      x: -maxScrollXThree, // Move the slides left by the calculated maxScrollX
      ease: 'none',   // No easing, so the animation syncs directly with scroll
      scrollTrigger: {
        trigger: '.parisCarouselFix',       // Element that triggers the scroll animation
        start: 'top 50px',                  // Pin starts when .journeyAnimation reaches 50px from the top
        end: () => `+=${maxScrollX}`,       // The animation ends after scrolling the calculated distance
        scrub: true,                        // The animation will scrub (sync) with the scroll position
        pin: true,                          // Pin the element during the scroll animation
        pinSpacing: true,                   // Adds space after pinning to allow continued scrolling
        anticipatePin: 1,                   // Helps with pin performance
        markers: false                      // Set to true if you want to visualize the trigger points
      }
    });

    
    }
    
    if (document.querySelectorAll('.triCarousel').length > 0) {
      let triCarousel = new Swiper(".triCarousel", {
        loop: false,
      
        pagination: {
          el: '.swiper-pagination-tri', 
          clickable: true, 
        },
        slidesPerView: 1,
        spaceBetween: 0,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          769: {
            slidesPerView: 1, 
            spaceBetween: 0
          }
        }
      });
    }
  if (document.querySelectorAll('.journeyCarousel').length > 0) {
    let journeyCarousel = new Swiper(".journeyCarousel", {
      loop: false,
    
    
      slidesPerView: 4,
      spaceBetween: 40,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 40
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40
        },
        769: {
          slidesPerView: 4, 
          spaceBetween: 40
        }
      }
    });
  }
if (document.querySelectorAll('.futuraCarousel').length > 0) {
  let futuraCarousel = new Swiper(".futuraCarousel", {
    loop: false,
  
    /*autoplay: {
      delay: 4000, 
      disableOnInteraction: true, 
    },*/
    slidesPerView: 3,
    spaceBetween: 40,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 40
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      769: {
        slidesPerView: 3, 
        spaceBetween: 40
      }
    }
  });
}
if (document.querySelectorAll('.commsCarousel').length > 0) {
  let commsCarousel = new Swiper(".commsCarousel", {
    loop: false,
  
    /*autoplay: {
      delay: 4000, 
      disableOnInteraction: true, 
    },*/
    pagination: {
      el: '.swiper-pagination-comms', 
      clickable: true, 
    },
    slidesPerView: 2,
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      769: {
        slidesPerView: 4, 
        spaceBetween: 20
      }
    }
  });
}

if (document.querySelectorAll('.runwayCarousel').length > 0) {
  let runwayCarousel = new Swiper(".runwayCarousel", {
    loop: false,
  
    /*autoplay: {
      delay: 4000, 
      disableOnInteraction: true, 
    },*/
    slidesPerView: 3,
    spaceBetween: 80,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      769: {
        slidesPerView: 3, 
        spaceBetween: 80
      }
    }
  });
}
if (document.querySelectorAll('.parisCarousel').length > 0) {
  let parisCarousel = new Swiper(".parisCarousel", {
    loop: false,
  
    /*autoplay: {
      delay: 4000, 
      disableOnInteraction: true, 
    },*/
    slidesPerView: 3,
    spaceBetween: 80,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      769: {
        slidesPerView: 3, 
        spaceBetween: 80
      }
    }
  });
}

if (document.querySelectorAll('.testimonialCarousel').length > 0) {
  let testimonialCarousel = new Swiper(".testimonialCarousel", {
    loop: false,
  
   
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      769: {
        slidesPerView: 1, 
        spaceBetween: 20
      }
    }
  });
}
if (document.querySelectorAll('.soundCarousel').length > 0) {
  let soundCarousel = new Swiper(".soundCarousel", {
    loop: false,
  
   
    slidesPerView: 3,
    spaceBetween: 80,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      769: {
        slidesPerView: 3, 
        spaceBetween: 80
      }
    }
  });
}

if (document.querySelectorAll('.mapCarousel').length > 0) {
  let mapCarousel = new Swiper(".mapCarousel", {
    loop: false,
  
    autoplay: {
      delay: 4000, 
      disableOnInteraction: true, 
    },
    slidesPerView: 2,
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      769: {
        slidesPerView: 2, 
        spaceBetween: 20
      }
    }
  });
}
  // auto loop swiper
  if (document.querySelectorAll('.footerCarousel').length > 0) {    
    const footerCarousel = new Swiper(".footerCarousel", {
      loop: true,
      freeMode: true,
      spaceBetween: 0,
      grabCursor: true,
      
      loop: true,
      autoplay: {
        delay: 1,
        disableOnInteraction: true
      },
      freeMode: true,
      speed: 5000,
      spaceBetween: 10,
      slidesPerView: 2,
      freeModeMomentum: false,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        769: {
          slidesPerView: 4, 
          spaceBetween: 20
        }
      }
    });
}
if (document.querySelectorAll('.highlightsCarousel').length > 0) {    
  const highlightsCarousel = new Swiper(".highlightsCarousel", {
    loop: true,
    freeMode: true,
    spaceBetween: 0,
    grabCursor: true,
    
    loop: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: true
    },
    freeMode: true,
    speed: 5000,
    spaceBetween: 0,
    slidesPerView: 'auto',
    freeModeMomentum: false,
    breakpoints: {
      320: {
        slidesPerView: 'auto',
        speed: 2000,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0
      },
      769: {
        slidesPerView: 'auto', 
        spaceBetween: 0
      }
    }
  });
}
  /*
  const firstTimeline = gsap.timeline({
  scrollTrigger: {
    trigger: '#firstParallax',
    start: 'top center',
    end: 'bottom top',
    scrub: true,
    markers: false
  }
});
const firstParallaxElements = document.querySelectorAll('#firstParallax .imageItem');

firstParallaxElements.forEach(images => {
  firstTimeline.to(images, {
    ease: 'none',
    yPercent: gsap.utils.random(-50, -10)
  },0);
});
*/
  /*
  $.ajax({
    type: 'post',
    
    url: new_vars.ajax_url,
    
    data: {
        action: 'load_projekte',
        projekte: projekte,                    
        nonce : new_vars.ajax_nonce
    }
    }).done(function (resp) {
       
        if(resp) {
          $('#projectOverlay').html(resp);
        }
        

    }).fail(function (resp) {

    }).always(function () {

    });*/
    

  
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var targetHash = this.hash;
            var decodedHash = decodeURIComponent(targetHash);
            var target = $(decodedHash);
            
            target = target.length ? target : $('[name=' + decodedHash.slice(1) +']');
            
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - 200
                }, 1000);
                return false;
            }
        }
    });

});